<template>
    <editor
        :id="id"
        :mode="mode_type"
        :type="contentType"
        :editing="editing"
        :item="form_data"
        @checkRouteForValidate="checkRouteForValidate"
        @reset="resetToPrew"
        @editPost="editPost"
        @createRevision="createRevision"
    >
        <template #contentFormFields>
            <keep-alive :max="10">
                <router-view
                    ref="content"
                    :form_data="form_data"
                    :loading="loading"
                    :fields="fields"
                    :validation-rules="validationRules"
                    :categories="selectCategories[contentType]"
                    :server-errors="serverErrors"
                    @update-data="getData"
                    @reset="reset"
                    @submit="submit"
                />
            </keep-alive>
        </template>
    </editor>
</template>

<script>
import customEditorMixins from '@/mixins/custom-editor/customEditorMixins'
import { mapBlogFields } from '@/store/modules/content/content-edit/blogs/index.js'

import { revisionItems } from '@/helpers/constants/custom-editor/blog/create-new-blog-revision-items'
import { editItems } from '@/helpers/constants/custom-editor/blog/edit-blog-post-items'
import { createItems } from '@/helpers/constants/custom-editor/blog/create-new-blog-post-items'

import {
    ACTION_UPDATE_POST,
    ACTION_GET_POST,
    ACTION_CREATE_REVISION,
    ACTION_VALIDATE_SLUG,
    ACTION_CREATE_POST
} from '@/store/modules/content/content-edit/action-types'
import {
    RESET_EDIT_FORM_DATA,
    RESET_CREATED_FORM_DATA
} from '@/store/modules/content/content-edit/mutation-types'
import {
    ACTION_GET_CATEGORY_LIST_FOR_SELECT
} from '@/store/modules/content/content-category/action-types'
import { createNamespacedHelpers, mapState } from 'vuex';
import { validationEditRules } from '@/validation/Content/EditBlog/EditRules'
import { validationRevisionRules } from '@/validation/Content/EditBlog/RevisionRules'
import { validationCreateRules } from '@/validation/Content/EditBlog/CreateRules'

const {
    mapActions: mapContentEditActions
} = createNamespacedHelpers('contentBlogEdit');
const {
    mapMutations: mapContentEditMutations
} = createNamespacedHelpers('contentBlogEdit');
const {
    mapActions: mapContentСategoriesActions
} = createNamespacedHelpers('contentCategory');

export default {
    name: 'EditBlogPage',
    mixins: [
        customEditorMixins
    ],
    computed: {
        ...mapBlogFields([
            'blog',
            'blog.form_data',
            'blog.form_data.id',
            'blog.default_form_data',
            'blog.editing',
            'blog.unfinished_session',
            'blog.unfinished_url',
            'blog.unfinished_type',
            'loading'
        ]),
        ...mapState('contentCategory', ['selectCategories']),
        editItems() {
            return editItems
        },
        revisionItems() {
            return revisionItems
        },
        createItems() {
            return createItems
        },
        validationRevisionRules() {
            return validationRevisionRules
        },
        validationCreateRules() {
            return validationCreateRules
        },
        validationEditRules() {
            return validationEditRules
        }
    },
    created() {
        this.editing = true
    },
    methods: {
        ...mapContentСategoriesActions([
            ACTION_GET_CATEGORY_LIST_FOR_SELECT
        ]),
        ...mapContentEditActions([
            ACTION_UPDATE_POST,
            ACTION_GET_POST,
            ACTION_CREATE_REVISION,
            ACTION_VALIDATE_SLUG,
            ACTION_CREATE_POST
        ]),
        ...mapContentEditMutations([
            RESET_EDIT_FORM_DATA,
            RESET_CREATED_FORM_DATA
        ])
    }
}
</script>
