import {
    // maxLength,
    // minLength,
    required
} from 'vuelidate/lib/validators';

import { validationBaseRules } from '../editRules'

export const validationEditRules = {
    form_data: {
        ...validationBaseRules,
        cover_image_url: {},
        post_slug: {
            required,
            async slugFormat(value) {
                if (!this.$v.form_data.post_slug.required || !value) return true;
                return new Promise((resolve) => {
                    if (/^[a-z0-9][a-z0-9-]+[a-z0-9]$/ig.test(value)) {
                        resolve(true)
                    } else {
                        resolve(false)
                    }
                })
            },
            async slugExists(value) {
                // ignore this validator, if any of the previous fail
                if (!this.$v.form_data.post_slug.required || !this.$v.form_data.post_slug.slugFormat || !value) return true;
                return new Promise((resolve) => {
                    const vm = this
                    this.validateSlug(value)
                        .then(() => {
                            resolve(true)
                        })
                        .catch(() => {
                            vm.$v.form_data.post_slug.$params.slugExists = true
                            resolve(false)
                        })
                })
            }
        },
        post_title: {
            // required,
            // minLength: minLength(10),
            // maxLength: maxLength(65)
        },
        post_excerpt: {
            // required,
            // minLength: minLength(5),
            // maxLength: maxLength(30)
        },
        post_content: {
            // required,
            // minLength: minLength(200),
            // maxLength: maxLength(50000)
        }
    }
}
