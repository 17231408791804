import {
    required,
    minLength,
    maxLength
} from 'vuelidate/lib/validators';

import { validationBaseRules } from '../revissionsRules'

export const validationRevisionRules = {
    form_data: {
        ...validationBaseRules,
        cover_image_url: {
            // required
        },
        post_title: {
            required,
            minLength: minLength(10),
            maxLength: maxLength(100)
        },
        post_excerpt: {
            required,
            minLength: minLength(70)
        },
        post_content: {
            required,
            minLength: minLength(200),
            maxLength: maxLength(65535)
        }
    }
}
